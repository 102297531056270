import { PureComponent } from 'react';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import { message } from 'antd';

@connect(({ dropshippersetting }) => ({
  dropshippersetting
}))
export default class AliExpressCallback extends PureComponent {
  componentDidMount() {
    this.pushCallbackData();
  }
  pushCallbackData = () => {
    const urlParams = new URL(window.location.href);
    const params = {};
    const keys = urlParams.searchParams.keys();
    for (const key of keys) {
      params[key] = urlParams.searchParams.get(key);
    }

    this.props.dispatch({
      type: 'dropshippersetting/aliexpresscallback',
      payload: {
        data: params,
        callback: data => {
          if (data.code === 1) {
            message.success('Authorization success');
          } else {
            message.error('Authorization failure');
          }
          const { dispatch } = this.props;
          dispatch(routerRedux.push('/')); // 首页
        }
      }
    });
  };

  render() {
    return '';
  }
}
